import type { Theme } from '@mui/material/styles'

import { extendTheme } from '@mui/material/styles'

import overridesTheme from './overridesTheme'
import { shadows, typography, components, palette, customShadows, mixins } from './core'
import type { ThemeColorScheme, ThemeOptions } from './types'

const createTheme = (mode: ThemeColorScheme = 'light'): Theme => {
  const initialTheme: ThemeOptions = {
    mode: 'light',
    defaultColorScheme: 'light',
    colorSchemes: {
      light: { palette: palette.light },
      dark: { palette: palette.dark }
    },
    // @ts-expect-error -- Mixins types not working properly. Will revisit.
    mixins,
    shadows: shadows[mode],
    customShadows: customShadows[mode],
    shape: { borderRadius: 8 },
    components,
    typography,
    // CSS Variables
    cssVarPrefix: '',
    colorSchemeSelector: 'class'
  }

  const theme = extendTheme(initialTheme, overridesTheme)

  return theme
}

export default createTheme
