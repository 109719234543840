'use client'

import type {} from '@mui/lab/themeAugmentation'
import type {} from '@mui/x-tree-view-pro/themeAugmentation'
import type {} from '@mui/x-data-grid-pro/themeAugmentation'
import type {} from '@mui/x-date-pickers-pro/themeAugmentation'
import type {} from '@mui/material/themeCssVarsAugmentation'

import { memo } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import InitColorSchemeScript from '@mui/material/InitColorSchemeScript'
import { AppRouterCacheProvider } from '@mui/material-nextjs/v15-appRouter'
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles'

import createTheme from './createTheme'
import type { ThemeColorScheme } from './types'

type Props = {
  children: React.ReactNode
  colorScheme?: ThemeColorScheme
}

const theme = createTheme('light')

export const themeBreakpoints = theme.breakpoints
export const themePalette = theme.palette

const cacheProviderOptions = { key: 'css', enableCssLayer: true }
const themeModeStorageKey = 'theme-mode'

const ThemeProvider = ({ children }: Props) => {
  return (
    <AppRouterCacheProvider options={cacheProviderOptions}>
      <MUIThemeProvider theme={theme} defaultMode="light" modeStorageKey={themeModeStorageKey}>
        <InitColorSchemeScript
          defaultMode="light"
          attribute="class"
          modeStorageKey={themeModeStorageKey}
        />
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </AppRouterCacheProvider>
  )
}

export default memo(ThemeProvider)
